import React, { useEffect } from 'react';
import $ from 'jquery';
const moment = require("moment");

const donationWallet = '0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f'
const donationTimeStart = 1730764800000
// const donationTimeEnd = 1743379200000
// const dnationPrice = { 1: 3, 2: 24, 3: 40.5, 4: 57, 5: 73.5, 6: 73.5, 7: 73.5, 8: 73.5, 9: 73.5, 10: 91 }

const callReferralRequest = () => {
    const windowWidth = window.outerWidth;
    const markPosition = windowWidth >= 1440 ? 3.5 : windowWidth >= 1024 ? 3 : windowWidth >= 768 ? 2.5 : windowWidth >= 425 ? 2 : windowWidth >= 375 ? 1.5 : 0.5;
    try {
        $.ajax({
            url: process.env.REACT_APP_DONATION_URL,
            method: "GET",
            dataType:'json',
        })
        .done((res) => {
            // $(".donationBar .triangle").css({ 'left': dnationPrice[Number(res[0].usd_price)] + '%' })
            $(".donationBar .triangle").css({ 'left': ((res[0].usd_price - 1) * 10 + markPosition) + '%' })
        })
        .fail((e) => {
            $(".donationBar .triangle").hide()
        })
    } catch (e) {
        $(".donationBar .triangle").hide()
    }
}

const Donation = () => {
    useEffect(() => {
        callReferralRequest()
        $("#donationContent").css("height", '100%')
        $(".headerSubMenu_Donation").addClass("selectedSub")
        $(".headerMenu_How").addClass("selected")
        $(".donationStartColor").css('background-color', "#E31ADE")
        $(".donationFinishColor").css('background-color', "#02D5E5")
    }, [])
    return (
        <>
            <div id="donationContent" className='contents'>
                <div className='contentBox'>
                    <p className='contentText'>
                        You can get Bitcoin Krypton through donation.<br />
                        Your donation will greatly help in the initial arrival of Bitcoin Krypton.<br /><br />
                        Donations can be made through USDT, and the donation address is as follows.<br /><br />
                        Donation Address :  <strong>{donationTimeStart <= moment.utc().valueOf() ? donationWallet : "Open Soon"}</strong> <br />
                        Donationable Coin :  USDT (Ethereum, Polygon, Binance)<br />
                        {/* Donation period :  <strong>5/November/2024 ~ 31/March/2025</strong><br /> */}
                        How to donate : If you send USDT to the donation address above, Bitcoin Krypton will be resent to the address you sent it to in proportion to the USDT you sent.<br /><br />
                        <strong>Compensation policy based on donation</strong><br />
                        There are a total of 10 donation stages, and each stage has 50,000 Bitcoin Krypton<br /><br />
                        Stage 1 rewards 1 BTCK for every 1 USDT<br/>
                        Stage 2 rewards 1 BTCK for every 2 USDT<br/>
                        Stage 3 rewards 1 BTCK for every 3 USDT<br/>
                        Stage 4 rewards 1 BTCK for every 4 USDT<br/>
                        Stage 5 rewards 1 BTCK for every 5 USDT<br/>
                        Stage 6 rewards 1 BTCK for every 6 USDT<br/>
                        Stage 7 rewards 1 BTCK for every 7 USDT<br/>
                        Stage 8 rewards 1 BTCK for every 8 USDT<br/>
                        Stage 9 rewards 1 BTCK for every 9 USDT<br/>
                        Stage 10 rewards 1 BTCK for every 10 USDT<br/>
                    </p>
                </div>
                <div className='contentBox donationChart'>
                    <div className='donationIcon'>
                        <div>
                            <div className='circle donationStartColor'></div>
                            <span className='whiteFont'>Start Donation</span>
                        </div>
                        <div>
                            <div className='circle donationFinishColor'></div>
                            <span className='whiteFont'>Finish Donation</span>
                        </div>
                    </div>
                    <div className='donationBar'>
                        <span className='donationPrice'>1 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>2 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>3 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>4 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>5 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>6 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>7 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>8 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>9 USDT</span>
                        <span className='v-line'></span>
                        <span className='donationPrice'>10 USDT</span>
                        <p className='triangle'></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Donation;